.pageTitle {
  margin-bottom: 40px;
  text-align: center;
}

.headerBanner {
  align-items: stretch !important;
}

.subtitle {
  color: var(--chakra-colors-chakra-body-text);
  font-weight: 400;
  margin-bottom: 10px;
}
